import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='Our Motto' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
        Everything is Possible, Latest Tech Reviews, Find DIY Creative Ideas To Improve Lifestyle!
      </Text>
    </Card>
  </Section>
)

export default Commitment
